<template>
  <div class="d-flex flex-row w-100">
      <button tyoe="button" class="btn btn-outline-info btn-block text-left" @click="onChangeSelected">
        {{ item.text }}
      </button>
    </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    onChangeSelected() {
      this.$emit('on-emit-change-selected', this.item.value)
    },
  },
}
</script>
