<template>
  <div>
    <dx-util-popup
      ref="planUpdateRef"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :show-close-button="true"
      :show-title="true"
      width="40vw"
      height="auto"
      :title="popupTitle"
      :on-hiding="onHiding"
    >
      <template #content>
        <div>
          <dx-util-position at="center" my="center" />
          <div class="mb-1  px-half">
            <b class="text-warning">Current Service Charge Plan: </b> {{ currentPlan }}
          </div>
          <div class="d-flex flex-row justify-content-between mb-half px-half">
            <b class="text-warning">
              Select a new service charge plan
            </b>
            <div v-if="useApplyAllPlan" class="text-warning font-weight-bolder ml-1">
            <dx-util-check-box
              v-model="applyAll"
              text="Apply to all batch items"
              class="text-warning font-weight-bolder ml-1"
            />
          </div>
          </div>
          <dx-util-list
            v-model="selectedPlan"
            :data-source="userPlans"
            type="success"
          >
            <template #item="{ data: item }">
              <PlanSelectorItemInfo :item="item" @on-emit-change-selected="onPlanSelected" />
            </template>
          </dx-util-list>
        </div>
      </template>
    </dx-util-popup>
  </div>
</template>

<script>
import useCurrentUser from '@/libs/app/current-user'
import planManagementService from '@/http/requests/plan/planManagementService'
import PlanSearchFilter from '@/http/models/search-filters/PlanSearchFilter'
import Pager from '@core/dev-extreme/utils/pager'
import PlanCategoryEnum from '@/enums/plan-category.enum'
import productsService from '@/http/requests/product/productsService'
import { Notify } from '@robustshell/utils/index'
import PlanSelectorItemInfo from './PlanSelectorItemInfo.vue'

export default {
  components: {
    PlanSelectorItemInfo,
  },
  props: {
    planId: {
      type: Number,
      default: 0,
    },
    productId: {
      type: Number,
      default: 0,
    },
    accountNo: {
      type: Number,
      default: 0,
    },
    companyId: {
      type: Number,
      default: 0,
    },
    showPlanSelector: {
      type: Boolean,
      default: false,
      required: true,
    },
    useApplyAllPlan: {
      type: Boolean,
      default: false,
    },
    useProductPlan: {
      type: Boolean,
      default: true,
    },
    planCategory: {
      type: String,
      default: PlanCategoryEnum.ALL.key,
    },
  },
  data() {
    return {
      currentPlan: '',
      selectedPlan: null,
      userPlans: [],
      applyAll: false,
    }
  },
  setup() {
    const {
      userWarehouseId,
    } = useCurrentUser()
    return {
      userWarehouseId,
    }
  },
  computed: {
    popupTitle() {
      return this.useApplyAllPlan ? 'Service Charge Plan' : 'Update Plan'
    },
  },
  watch: {
    showPlanSelector: {
      handler(val) {
        if (val) {
          this.openPlanOptions()
        }
      },
    },
  },
  methods: {
    openPlanOptions() {
      const planInfo = {
        companyId: this.companyId,
        warehouseId: this.userWarehouseId,
        accountNumber: this.accountNo,
        productId: this.productId,
        planId: this.planId,
      }
      this.getUserPlans(planInfo)
    },
    getUserPlans(planInfo) {
      this.userPlans = []
      PlanSearchFilter.setDefaultFilters()
      PlanSearchFilter.warehouseId = planInfo.warehouseId
      PlanSearchFilter.companyId = planInfo.companyId
      PlanSearchFilter.accountNo = planInfo.accountNumber
      PlanSearchFilter.planCategory = this.planCategory
      const searchFilter = PlanSearchFilter.getFilters()
      const pager = new Pager({})

      planManagementService
        .fetchAllUserPlansByQuery(searchFilter, pager.staticPageable)
        .then(result => {
          const data = result.data.content
          data.forEach(element => {
            if ((element.companyId === planInfo.companyId)) {
              const name = `${element.planCategory.toUpperCase()} : ${element.planName} : ${element.planType} : $${element.itemFee}`
              if (element.id !== planInfo.planId) {
                this.userPlans.push({
                  value: element.id,
                  text: name,
                })
              } else {
                this.currentPlan = name
              }
            }
          })
          if (this.userPlans.length > 0) {
            const popup = this.$refs.planUpdateRef.instance
            popup.show()
          } else {
            Notify.warning('There is no other plan options to select from!')
            this.$emit('close')
          }
        })
    },
    onPlanSelected(selectedPlanId) {
      const popup = this.$refs.planUpdateRef.instance
      const newPlanId = selectedPlanId
      if (this.useApplyAllPlan) {
        const payload = {
          planId: newPlanId,
          productId: this.productId,
          applyAll: this.applyAll,
        }
        this.$emit('planUpdated', payload)
        popup.hide()
        return
      }
      if (this.useProductPlan) {
        productsService.updatePlan(this.productId, newPlanId)
          .then(() => {
            this.$emit('planUpdated')
          })
          .then(() => {
            popup.hide()
          })
          .catch(() => {
            popup.hide()
          })
      } else {
        const payload = {
          planId: newPlanId,
          productId: this.productId,
          applyAll: this.applyAll,
        }
        this.$emit('planUpdated', payload)
        popup.hide()
      }
    },
    onHiding() {
      this.$emit('close')
    },
  },

}
</script>

<style lang="scss" scoped>

</style>
