/**
 * Enum for Plan Categories
 * @readonly
 * @enum {{key: string, value: string, text: string}}
 */

const PlanCategoryEnum = Object.freeze({
  ALL: { key: 'all', value: 0, text: 'All' },
  FBA: { key: 'fba', value: 1, text: 'FBA' },
  FBM: { key: 'fbm', value: 2, text: 'FBM' },
  STORAGE: { key: 'storage', value: 3, text: 'Storage' },
  FORWARDING: { key: 'forwarding', value: '4', text: 'Forwarding' },
  DROPSHIP: { key: 'dropship', value: 6, text: 'Dropship' },
  B2B: { key: 'b2b', value: 7, text: 'B2B' },
  WFS: { key: 'wfs', value: 8, text: 'WFS' },
  RETURN_P: { key: 'return_p', value: 9, text: 'Return' },

  list: () => Object.values(PlanCategoryEnum)
    .filter(value => typeof value === 'object')
    .map(value => ({
      value: value.value,
      text: value.text,
    })),
  find: query => Object.values(PlanCategoryEnum)
    .find(value => typeof value === 'object' && (value.value === query)),
})

export default PlanCategoryEnum
